<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" fixed temporary dark right clipped>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-img
            alt="AnnuityCheck"
            class="mr-2 mt-5 mb-5"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="100%"
          />

          <v-list-item
            link
            v-for="(tab, index) in $store.state.user.is_login
              ? loggedInMenu
              : loggedOutMenu"
            :key="index"
            :to="tab.custom_action ? '' : tab.to"
            @click.stop="tab.custom_action ? tab.custom_action() : null"
          >
            <v-list-item-icon>
              <v-icon>{{ tab.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ tab.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-if="$store.state.user.acl === 10">
            <v-divider class="mt-2 mb-2"></v-divider>
            <h3>Admin</h3>
            <v-list-item
              link
              v-for="(tab, index) in admin"
              :key="index"
              :to="tab.to"
            >
              <v-list-item-icon>
                <v-icon>{{ tab.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ tab.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark elevation="0" color="black">
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="Recomposition"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="250"
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-tabs right optional v-else v-model="tab">
        <v-tab
          v-for="(tab, index) in $store.state.user.is_login
            ? loggedInMenu
            : loggedOutMenu"
          :key="index"
          :to="tab.custom_action ? '' : tab.to"
          @click.stop="tab.custom_action ? tab.custom_action() : null"
        >
          {{ tab.text }}
        </v-tab>
        <v-menu
          bottom
          offset-y
          open-on-hover
          v-if="$store.state.user.acl === 10"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="align-self-center mr-4" v-bind="attrs" v-on="on">
              Admin
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              v-for="(tab, index) in admin"
              :key="index"
              :to="tab.to"
            >
              <v-list-item-content>
                <v-list-item-title> {{ tab.text }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tabs>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar v-model="$store.state.snackbar.show">
      {{ $store.state.snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="$store.state.snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: null,
    titleTemplate: (titleChunk) => {
      // If undefined or blank then we don't need the hyphen
      return titleChunk ? `${titleChunk} | Recomposition` : "Recomposition";
    },
    htmlAttrs: {
      lang: "en-US",
    },

    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Recompose your body and mind.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  created() {},
  mounted() {
    this.$vuetify.theme.dark = false;
    // if (localStorage?.dark === "true") {
    //   this.$vuetify.theme.dark = true;
    //   localStorage.dark = "true";
    // } else {
    // }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  },
  methods: {
    signout() {
      this.$store.commit("signout");
      // using href here to clear state
      window.location.href = "/";
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
    $route(to) {
      if (to.path === "/calculators") {
        this.tab = null;
        this.$nextTick(() => {
          this.tab = "/calculators";
        });
      }
      if (to.path.includes("admin")) {
        this.$nextTick(() => {
          this.tab = null;
        });
      }
    },
  },
  data() {
    return {
      tab: null,
      drawer: false,
      group: null,
      admin: [
        {
          text: "Agents",
          icon: "mdi-account",
          to: "/admin/agents",
        },
        {
          text: "Cases",
          icon: "mdi-finance",
          to: "/admin/proposals",
        },
        {
          text: "Campaigns",
          icon: "mdi-email-newsletter",
          to: "/admin/seminars",
        },
        {
          text: "Bootcamp Events",
          icon: "mdi-calendar",
          to: "/admin/events",
        },
      ],
      loggedOutMenu: [
        {
          text: "Home",
          icon: "mdi-home",
          to: "/",
        },
        {
          text: "About",
          icon: "mdi-home",
          to: "/about",
        },
        {
          text: "Blog",
          icon: "mdi-home",
          to: "/blog",
        },
        {
          text: "Sign In",
          icon: "mdi-login",
          to: "/login",
        },
      ],
      loggedInMenu: [
        {
          text: "Home",
          icon: "mdi-home",
          to: "/",
        },
      ]
        .concat(
          this.$store.state.user.approved
            ? [
                {
                  text: "Cases",
                  icon: "mdi-calculator",
                  to: "/client",
                },
              ]
            : []
        )
        .concat(
          this.$store.state.user.approved
            ? [
                {
                  text: "Campaigns",
                  icon: "mdi-email-newsletter",
                  to: "/seminar",
                },
              ]
            : []
        )
        .concat([
          {
            text: "Account",
            icon: "mdi-account",
            to: "/account",
          },
          {
            text: "Sign Out",
            icon: "mdi-logout",
            to: "/",
            custom_action: this.signout,
          },
        ]),
      //
    };
  },
};
</script>
