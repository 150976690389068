import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    next_route: null,
    user: {
      token: null,
      is_login: false,
      id: null,
      acl: null,
      first_name: "",
      last_name: "",
      email: "",
    },
    snackbar: {
      show: false,
      text: "",
    },
  },
  getters: {},
  mutations: {
    set_snackbar(state, payload) {
      state.snackbar.show = true;
      state.snackbar.text = payload;
    },
  },
  actions: {},
  modules: {},
});
